export const VALID_BID = "Valid";
export const DISPOSITION_VERIFY_LOAD = "Verify Load";
export const DISPOSITION_PRICE_MISMATCH = "Price Mismatch";

export const CallingDispositions = {
    bid: [
        {
            dispositionName: "Bid Placed",
            subDispositions: []
        },
        {
            dispositionName: "Ongoing",
            subDispositions: []
        },
        {
            dispositionName: "Load Ghosted After Lorry Deposit",
            subDispositions: []
        },
        {
            dispositionName: "Load Canceled",
            subDispositions: ["Material Not Yet Ready", "Vehicle Placed From Outside"]
        },
        {
            dispositionName: "Load Canceled After Lorry Deposit",
            subDispositions: ["Material Not Yet Ready", "Vehicle Placed From Outside"]
        },
        {
            dispositionName: "Not Ready To Pay Deposit",
            subDispositions: []
        },
        {
            dispositionName: DISPOSITION_PRICE_MISMATCH,
            subDispositions: []
        },
        {
            dispositionName: "Matchmaking Done",
            subDispositions: ["In-Transit", "Lorry Cancelled", "Load Cancelled", "Ongoing"]
        },
        {
            dispositionName: "Truck Length Mismatch",
            subDispositions: []
        },
        {
            dispositionName: "Truck Capacity Mismatch",
            subDispositions: []
        },
        {
            dispositionName: "By-Mistakenly Bid Placed",
            subDispositions: []
        },
        {
            dispositionName: "Not connected",
            subDispositions: ["DNP", "DND"]
        },
        {
            dispositionName: "Lorry Ghosted",
            subDispositions: ["No Response After Bid Sent", "Will Check and Get Back To You/DNP"]
        },
        {
            dispositionName: "Vehicle Agreed Load Owner Not Responding",
            subDispositions: []
        },
        {
            dispositionName: "Vehicle Not Available",
            subDispositions: []
        },
        {
            dispositionName: "Vehicle Not Available At Source location",
            subDispositions: []
        },
        {
            dispositionName: "Vehicle placed for local load",
            subDispositions: []
        }
    ],
    "live-lorries": [
        {
            dispositionName: "Bid Sent",
            subDispositions: [
                DISPOSITION_PRICE_MISMATCH,
                "Vehicle Placed for outside load",
                "Ongoing",
                "Bid Accepted ",
                "Lorry Ghosted After Bid Sent"
            ]
        },
        {
            dispositionName: "Bid Not Sent",
            subDispositions: ["Truck Type mismatch", "Pickup/Destination not serviceable", "Vehicle not available"]
        },
        {
            dispositionName: "Not Connected",
            subDispositions: ["RNR"]
        },
        {
            dispositionName: "CD- Not Interested",
            subDispositions: []
        },
        {
            dispositionName: "Vehicle Not Available At Source location",
            subDispositions: []
        },
        {
            dispositionName: "Vehicle placed for local load.",
            subDispositions: []
        }
    ],
    "tap-n-go-load": [
        {
            dispositionName: DISPOSITION_VERIFY_LOAD,
            subDispositions: []
        },
        {
            dispositionName: "Matchmaking Done",
            subDispositions: ["In-Transit", "Lorry Cancelled", "Load Cancelled", "Ongoing"]
        },
        {
            dispositionName: "Matchmaking Not Done",
            subDispositions: [
                DISPOSITION_PRICE_MISMATCH,
                "Truck Length Mismatch",
                "Truck Capacity Mismatch",
                "Load Ghosted after Lorry Deposit",
                "Load Canceled after Lorry Deposit",
                "Lorry Owner Not Ready To Pay Deposit",
                "Vehicle Agreed Load Owner Not Responding",
                "Load Owner Not Ready To Pay Deposit",
                "Vehicle Not Available",
                "Load Cancelled"
            ]
        },
        {
            dispositionName: "Invalid",
            subDispositions: [
                "Suspected Load",
                "Mistook Feature",
                "Test load",
                "Duplicate",
                "Customer Disconnected",
                "Part load",
                "Load expired"
            ]
        },
        {
            dispositionName: "Not Connected",
            subDispositions: ["DNP", "RNR"]
        }
    ]
};
